<template>
	<v-tooltip v-bind="$attrs" z-index="300">
		<template v-slot:activator="{ on }">
			<v-btn v-if="canDeletePage" :small="smallBtn" icon :loading="deleteDocumentPageLoading" v-on="on" @click="showDeleteDialog()">
				<v-icon>delete</v-icon>
			</v-btn>
		</template>
		<span v-t="'actions.delete_page'" />
		<w-dialog v-if="dialogDelete" v-model="dialogDelete" max-width="400" :title="$t('actions.delete_page')" @close="closeDeleteDialog()">
			<v-layout>
				<v-flex>{{ $t('documents.preview.delete_confirm_page', {page: page}) }}</v-flex>
			</v-layout>
			<template v-slot:actions>
				<v-layout row>
					<v-spacer />
					<w-button :disable="deleteDocumentPageLoading" flat @click="deletePage()">{{ $t('actions.yes') }}</w-button>
					<w-button :disable="deleteDocumentPageLoading" flat @click="closeDeleteDialog()">{{ $t('actions.no') }}</w-button>
				</v-layout>
			</template>
	</w-dialog>
	</v-tooltip>
</template>

<script>
import { mapState } from 'vuex'
import DocumentVersionsModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentVersionsModuleGuard'
import * as Files from '@/helpers/files'
import SmallButton from "@/mixins/SmallButton";

export default {
	name: 'DeletePDFDocumentPageButton',
	mixins: [
			DocumentVersionsModuleGuard,
			SmallButton
	],
	props: {
		document: {
			type: Object,
			required: true
		},
		isReadonly: {
			type: Boolean,
			required: false,
			default: true
		},
		isEditable: {
			type: Boolean,
			required: false,
			default: false
		},
		page: {
			type: Number,
			required: true
		},
		version: {
			type: Object,
			required: true
		}
	},
	data: function () {
		return {
			deleteDocumentPageLoading: false,
			dialogDelete: false
		}
	},
	computed: {
		...mapState({
			isGuest: state => state.company.userRoles.isGuest
		}),
		handledMimetype: function () {
			return [Files.mimetypes.pdf].includes(this.document.type)
		},
		canDeletePage: function () {
			return (
				!this.isReadonly &&
				this.isEditable &&
				this.document != null &&
				!this.isGuest &&
				!this.document.is_readonly &&
				!this.document.deleted_at &&
				this.handledMimetype
			)
		},
	},
	methods: {
		deletePage: function () {
			this.deleteDocumentPageLoading = true
			const data = {
				page_number: this.page
			}
			return this.service
				.updateDocumentVersion(this.vendorId, this.document.id, this.version, null, data)
				.then(() => {
					this.eventBus.emit(this.events.preview.DELETE_DOCUMENT_PAGE)
				})
				.finally(() => {
					this.deleteDocumentPageLoading = false
					this.closeDeleteDialog()
				})
		},

		showDeleteDialog: function () {
			this.dialogDelete = true
		},

		closeDeleteDialog: function () {
			this.dialogDelete = false
		}
	}
}
</script>
